import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Client, ClientSelectorData } from '../../models/client';
import { User } from '../../models/user.model';
import { StorageService } from '../../services/local-storage.service';
import { UserService } from '../../services/user.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-client-selector',
  templateUrl: './client-selector.component.html',
  styleUrls: ['./client-selector.component.scss']
})
export class ClientSelectorComponent extends BaseComponent implements OnInit {
  @Output() onSelectionChange = new EventEmitter<string>();
  @Input() isReadonly = false;

  private currentUser!: User;
  private lastSelection?: string;
  data!: ClientSelectorData;

  constructor(private readonly userService: UserService, private readonly storageService: StorageService) {
    super();
  }

  get canPickClient(): boolean {
    return this.currentUser.roleClients.length > 1 ? true : false;
  }

  ngOnInit(): void {
    this.currentUser = this.userService.currentUser;
    this.loadSelectionFromCache();
    this.loadClients(this.userService.getClients());
  }

  setSelection(clientGuid: string): void {
    this.data.clientGuid = clientGuid;
    this.emitSelection();
  }

  private loadSelectionFromCache(): void {
    this.data = this.storageService.lastClientSelectorData;
    this.emitSelection();
  }

  private emitSelection(): void {
    if (this.hasSelectionChanged()) {
      this.saveSelectionToCache();
      this.selectedClientGuid = this.data.clientGuid;
      this.lastSelection = this.data.clientGuid;
      this.onSelectionChange.emit(this.data.clientGuid);
    }
  }

  private hasSelectionChanged(): boolean {
    return this.lastSelection !== this.data.clientGuid;
  }

  private loadClients(service: Observable<Client[]>) {
    service.pipe(takeUntil(this.destroyed$)).subscribe((clients) => {
      this.data.clients = clients;
      if (this.data.clients.length === 1) {
        this.selectedClientGuid = this.data.clients[0].clientGuid;
      }
      this.resetClientGuidIfInvalid();
    });
  }

  private resetClientGuidIfInvalid(): void {
    if (!this.data.clients.length) {
      this.data.clientGuid = '';
      return;
    }

    const clientGuids = this.data.clients.map((u) => u.clientGuid);
    if (clientGuids.includes(this.data.clientGuid)) {
      return;
    }
    this.data.clientGuid = this.data.clients[0].clientGuid;
    this.data.clientDateFormat = this.data.clients[0].dateFormat!;
  }

  onClientChange(): void {
    this.emitSelection();
    this.reloadComponent();
  }

  private saveSelectionToCache(): void {
    this.storageService.lastClientSelectorData = this.data;
  }

  reloadComponent(): void {
    location.reload();
  }
}
