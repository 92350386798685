import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification.service';
@Injectable()
abstract class BaseApiService {
  protected abstract readonly baseUrl: string;
  protected constructor(private readonly http: HttpClient, private readonly notify: NotificationService) {}

  protected getMappedOne<T>(url: string, type: { new (source: Partial<T>): T }): Observable<T> {
    return this.http.get<T>(this.baseUrl + url).pipe(
      catchError((e) => this.handleError(e)),
      map((result) => new type(result))
    );
  }

  protected getOne<T>(url: string, notifyServerErrors = true): Observable<T> {
    return this.http.get<T>(this.baseUrl + url).pipe(catchError((e) => this.handleError(e, notifyServerErrors)));
  }

  protected getMappedList<T>(url: string, type: { new (source: Partial<T>): T }): Observable<T[]> {
    return this.http.get<T[]>(this.baseUrl + url).pipe(
      catchError((e) => this.handleError(e)),
      map((result) => {
        return result.map((item) => new type(item));
      })
    );
  }

  protected getRemappedList<T, K>(url: string, type: { new (source: K): T }): Observable<T[]> {
    return this.http.get<K[]>(this.baseUrl + url).pipe(
      catchError((e) => this.handleError(e)),
      map((result) => {
        return result.map((item) => new type(item));
      })
    );
  }

  protected postMappedList<T>(url: string, body: unknown, type: { new (source: Partial<T>): T }): Observable<T[]> {
    return this.http.post<T[]>(this.baseUrl + url, body).pipe(
      catchError((e) => this.handleError(e)),
      map((result) => {
        return result.map((item: T) => new type(item));
      })
    );
  }

  protected postMappedOne<T>(
    url: string,
    body: unknown,
    type: { new (source: Partial<T>): T },
    loader: boolean | undefined = undefined
  ): Observable<T> {
    return this.http.post<T>(this.baseUrl + url, body, { headers: loader === undefined ? {} : { loader: loader ? 'true' : 'false' } }).pipe(
      catchError((e) => this.handleError(e)),
      map((result) => new type(result))
    );
  }

  protected getList<T>(url: string): Observable<T[]> {
    return this.http.get<T[]>(this.baseUrl + url).pipe(catchError((e) => this.handleError(e)));
  }

  protected post<T>(url: string, body: unknown, notifyServerErrors = true): Observable<T> {
    return this.http.post<T>(this.baseUrl + url, body).pipe(catchError((e) => this.handleError(e, notifyServerErrors)));
  }

  protected getFile(url: string): Observable<void> {
    return new Observable((subscriber) => {
      this.http
        .get(this.baseUrl + url, {
          responseType: 'blob' as 'json',
          observe: 'response'
        })
        .subscribe(
          (result) => {
            this.downloadFile(result);
            subscriber.next();
          },
          (error) => {
            this.handleError(error);
            subscriber.error(error);
          }
        );
    });
  }

  protected getFileByName(url: string, fileName: string): Observable<void> {
    return new Observable((subscriber) => {
      this.http
        .get(this.baseUrl + url, {
          responseType: 'blob'
        })
        .subscribe(
          (result) => {
            saveAs(result, fileName);
            subscriber.next();
          },
          (error) => {
            this.handleError(error);
            subscriber.error(error);
          }
        );
    });
  }

  protected deleteOne<T>(url: string, body: unknown, notifyServerErrors = true): Observable<T> {
    return this.http.delete<T>(this.baseUrl + url, { body: body }).pipe(catchError((e) => this.handleError(e, notifyServerErrors)));
  }

  private downloadFile(resp: HttpResponse<unknown>): void {
    const fileName = resp?.headers.get('Content-Disposition');
    const splittedFilename = fileName?.split(';')[1].split('filename')[1].split('=')[1].trim().replace(/"/g, '');
    saveAs(resp.body as Blob, splittedFilename);
  }

  private handleError(error: HttpErrorResponse, notifyServerErrors = true) {
    if (error.status === 0) {
      console.error(`A client-side error occurred: ${error.error}`);
      this.notify.failure('Network error occurred. Please check your connection.');
    } else if (error.status === 400) {
      if (error.error.message === 'Record modified') {
        this.notify.failure('Request have been modified by another user. Please reopen.');
      } else {
        this.notify.failure('Invalid data.');
      }
    } else if (error.status === 401 && error.error.message === 'Access Denied.') {
      this.notify.failure('You have no longer access to this record.');
    } else {
      console.error(`A server-side error (${error.status}) occurred: ${error.error}`);
      if (notifyServerErrors) {
        this.notify.genericError();
      }
    }
    return throwError(error);
  }
}
@Injectable()
export abstract class BaseDataService extends BaseApiService {
  protected readonly baseUrl = environment.azureDataFunctionUrl;

  protected constructor(http: HttpClient, notify: NotificationService) {
    super(http, notify);
  }
}
