import { HttpClient } from '@angular/common/http'; //PRB0041478
import { Injectable } from '@angular/core';
import moment from 'moment'; //PRB0041478
import { Observable } from 'rxjs';
import { FunctionUrls } from 'src/app/shared/constants/function-urls.constant';
import { CommissionFile } from 'src/app/shared/models/commission-file';
import { CommissionRequestParamsModel } from 'src/app/shared/models/commission-request-filter-Params';
import { CommissionRequest } from 'src/app/shared/models/commissionRequest';
import { Country } from 'src/app/shared/models/country';
import { Currency } from 'src/app/shared/models/currency';
import { PaginatedListItem } from 'src/app/shared/models/paginated-list-item';
import { State } from 'src/app/shared/models/state';
import { UnitOfMeasurement } from 'src/app/shared/models/unit-of-measurement';
import { BaseDataService } from 'src/app/shared/services/base-api-service';
import { StorageService } from 'src/app/shared/services/local-storage.service'; //PRB0041478
import { NotificationService } from 'src/app/shared/services/notification.service'; //PRB0041478
import { RequestAudit } from '../models/requestAudit';

@Injectable({
  providedIn: 'root'
})
export class CommissionRequestService extends BaseDataService {
  dateFormat = ''; //PRB0041478 start
  protected constructor(http: HttpClient, notify: NotificationService, private readonly storageService: StorageService) {
    super(http, notify);
  } //PRB0041478 end

  getData(filterParams: CommissionRequestParamsModel): Observable<PaginatedListItem<CommissionRequest>> {
    return new Observable((subscriber) => {
      this.postMappedOne<PaginatedListItem<CommissionRequest>>(FunctionUrls.dataApi.getCommissionRequests, filterParams, PaginatedListItem).subscribe(
        (result) => {
          subscriber.next(result);
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }

  getExcelData(filterParams: CommissionRequestParamsModel): Observable<CommissionRequest[]> {
    return this.postMappedList<CommissionRequest>(FunctionUrls.dataApi.getExcelData, filterParams, CommissionRequest);
  }

  getDataByGuid(guid: string): Observable<CommissionRequest> {
    return this.getMappedOne<CommissionRequest>(`${FunctionUrls.dataApi.getCommissionRequest}/${guid}`, CommissionRequest);
  }

  create(data: CommissionRequest): Observable<string> {
    this.dateFormat = this.storageService.lastClientSelectorData.clientDateFormat.split(' ')[0]; // PRB0041478 start
    if (data.projectStartDate && data.projectStartDate != '') {
      const temp = moment(data.projectStartDate, this.dateFormat.toUpperCase()).format('L');
      const projectStartMonth = temp ? new Date(temp).getMonth() + 1 : '';
      data.projectStartDate = projectStartMonth + '/' + new Date(temp || '').getDate() + '/' + new Date(temp || '').getFullYear();
    } // PRB0041478 end
    if (data.projectDueDate && data.projectDueDate != '') {
      this.dateFormat = this.storageService.lastClientSelectorData.clientDateFormat.split(' ')[0]; //PRB0041478 start
      const temp = moment(data.projectDueDate, this.dateFormat.toUpperCase()).format('L');
      const projectDueMonth = temp ? new Date(temp).getMonth() + 1 : '';
      data.projectDueDate = projectDueMonth + '/' + new Date(temp || '').getDate() + '/' + new Date(temp || '').getFullYear(); //PRB0041478 end
    }
    if (data.dateCreated && data.dateCreated != '') {
      this.dateFormat = this.storageService.lastClientSelectorData.clientDateFormat.split(' ')[0];
      const temp = moment(data.dateCreated, this.dateFormat.toUpperCase()).format('L');
      const createdDateMonth = temp ? new Date(temp).getMonth() + 1 : '';
      data.dateCreated = createdDateMonth + '/' + new Date(temp || '').getDate() + '/' + new Date(temp || '').getFullYear();
    }
    return this.post<string>(FunctionUrls.dataApi.createCommissionRequest, data);
  }

  uploadFile(data: FormData): Observable<string> {
    return this.post<string>(FunctionUrls.dataApi.documentUpload, data);
  }

  downloadDocument(fileGuid: string): Observable<void> {
    return this.getFile(`${FunctionUrls.dataApi.downloadDocument}/${fileGuid}`);
  }

  deleteDocument(fileGuid: string): Observable<void> {
    return this.deleteOne(`${FunctionUrls.dataApi.deleteDocument}/${fileGuid}`, fileGuid);
  }

  getUploadFiles(guid: string): Observable<CommissionFile[]> {
    return new Observable((subscriber) => {
      this.getMappedList<CommissionFile>(`${FunctionUrls.dataApi.getUploadedDocument}/${guid}`, CommissionFile).subscribe(
        (result) => {
          subscriber.next(result);
        },
        (error) => {
          subscriber.error(error);
        }
      );
    });
  }

  getAuditData(guid: string): Observable<RequestAudit[]> {
    return new Observable((subscribe) => {
      this.getMappedList<RequestAudit>(`${FunctionUrls.dataApi.getCommissionRequestAudits}/${guid}`, RequestAudit).subscribe(
        (result) => {
          subscribe.next(result);
        },
        (error) => {
          subscribe.error(error);
        }
      );
    });
  }

  getCurrencies(): Observable<Currency[]> {
    return new Observable((subscribe) => {
      this.getMappedList<Currency>(`${FunctionUrls.dataApi.getCurrencies}`, Currency).subscribe(
        (result) => {
          subscribe.next(result);
        },
        (error) => {
          subscribe.error(error);
        }
      );
    });
  }

  getCountries(): Observable<Country[]> {
    return new Observable((subscribe) => {
      this.getMappedList<Country>(`${FunctionUrls.dataApi.getCountries}`, Country).subscribe(
        (result) => {
          subscribe.next(result);
        },
        (error) => {
          subscribe.error(error);
        }
      );
    });
  }

  getStates(): Observable<State[]> {
    return new Observable((subscribe) => {
      this.getMappedList<State>(`${FunctionUrls.dataApi.getStates}`, State).subscribe(
        (result) => {
          subscribe.next(result);
        },
        (error) => {
          subscribe.error(error);
        }
      );
    });
  }

  getUOMs(): Observable<UnitOfMeasurement[]> {
    return new Observable((subscribe) => {
      this.getMappedList<UnitOfMeasurement>(`${FunctionUrls.dataApi.getUOM}`, UnitOfMeasurement).subscribe(
        (result) => {
          subscribe.next(result);
        },
        (error) => {
          subscribe.error(error);
        }
      );
    });
  }
}
