import { Component, OnInit } from '@angular/core';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CONSTANTS } from '../../constants/project.constant';
import { LoadingService } from '../../services/loading.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent extends BaseComponent implements OnInit {
  public loading = false;
  constructor(private readonly loadingService: LoadingService) {
    super();
  }

  ngOnInit(): void {
    this.loadingService.loadingStatus.pipe(debounceTime(CONSTANTS.loadingSpinnerDelay), takeUntil(this.destroyed$)).subscribe((value) => {
      this.loading = value;
    });
  }
}
