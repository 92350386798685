<table>
  <tbody id="content">
    <tr *ngIf="savedFields?.includes('Recitals')">
      <th class="header">Recitals</th>
      <td [innerHTML]="recitals">
        {{ recitals }}
      </td>
    </tr>
    <tr>
      <th class="header">Project Name</th>
      <td [innerHTML]="projectName">
        {{ selectedData?.projectName }}
      </td>
    </tr>
    <tr>
      <th class="header">Business Unit</th>
      <td>
        {{ selectedData?.clientGroupDescription }}
      </td>
    </tr>
    <tr *ngIf="savedFields?.includes('Project Drivers')">
      <th class="header">Project Drivers</th>
      <td [innerHTML]="projectDrivers">
        {{ projectDrivers }}
      </td>
    </tr>
    <tr>
      <th class="header">Country</th>
      <td>
        {{ selectedData?.countryName }}
      </td>
    </tr>
    <tr *ngIf="savedFields?.includes('State')">
      <th class="header">State</th>
      <td>
        {{ selectedData?.stateName }}
      </td>
    </tr>
    <tr>
      <th class="header">City</th>
      <td>
        {{ selectedData?.city }}
      </td>
    </tr>
    <tr>
      <th class="header">Address</th>
      <td [innerHTML]="address">
        {{ address }}
      </td>
    </tr>
    <tr>
      <th class="header">Property ID</th>
      <td>
        {{ selectedData?.propertyId }}
      </td>
    </tr>
    <tr *ngIf="savedFields?.includes('Property Type')">
      <th class="header">Property Type</th>
      <td>
        {{ selectedData?.propertyTypeName }}
      </td>
    </tr>
    <tr *ngIf="savedFields?.includes('Current Area')">
      <th class="header">Current Area</th>
      <td>
        {{ selectedData?.currentArea != null ? selectedData?.currentArea : '' + ' ' }}
        {{ selectedData?.unitName != null ? selectedData?.unitName : '' }}
      </td>
    </tr>
    <tr *ngIf="savedFields?.includes('Target Area')">
      <th class="header">Target Area</th>
      <td>
        {{ selectedData?.targetArea != null ? selectedData?.targetArea : ' ' + ' ' }}
        {{ selectedData?.unitName != null ? selectedData?.unitName : '' }}
      </td>
    </tr>
    <tr>
      <th class="header">Project Start Date</th>
      <td>
        {{ projectStartDate }}
      </td>
    </tr>
    <tr *ngIf="savedFields?.includes('Project Due Date')">
      <th class="header">Project Due Date</th>
      <td>
        {{ projectDueDate }}
      </td>
    </tr>
    <tr>
      <th class="header">Confidential</th>
      <td *ngIf="selectedData?.isConfidential">Yes</td>
      <td *ngIf="!selectedData?.isConfidential">No</td>
    </tr>
    <tr *ngIf="savedFields?.includes('C&W Contracting Entity')">
      <th class="header">C&W Contracting Entity</th>
      <td>
        {{ selectedData?.cwContractingEntity }}
      </td>
    </tr>
    <tr *ngIf="savedFields?.includes('Client Contracting Entity')">
      <th class="header">Client Contracting Entity</th>
      <td>
        {{ selectedData?.clientContractingEntity }}
      </td>
    </tr>
    <tr>
      <th class="header">Client Lead</th>
      <td>
        {{ selectedData?.requesterName }}
      </td>
    </tr>
    <tr>
      <th class="header">C&W Lead</th>
      <td>
        {{ selectedData?.cwLeadName }}
      </td>
    </tr>
    <tr>
      <th class="header">C&W Delegate</th>
      <td>
        {{ selectedData?.cwDelegateName }}
      </td>
    </tr>
    <tr *ngIf="savedFields?.includes('Scope Of Service')">
      <th class="header">Scope of Services</th>
      <td [innerHTML]="scopeOfService">
        {{ scopeOfService }}
      </td>
    </tr>
    <tr>
      <th class="header">Fee Structure</th>
      <td [innerHTML]="feeStructure">
        {{ feeStructure }}
      </td>
    </tr>
    <tr>
      <th class="header">Estimated Fee Amount</th>
      <td>
        {{ this.selectedData?.currencyType != null ? selectedData?.currencyType : '' + ' ' }}
        {{ selectedData?.estimatedFeeAmount != null ? selectedData?.estimatedFeeAmount : '' }}
      </td>
    </tr>
    <tr *ngIf="savedFields?.includes('Minimum Fee')">
      <th class="header">Minimum Fee</th>
      <td [innerHTML]="minimumFee">
        {{ minimumFee }}
      </td>
    </tr>
    <tr *ngIf="savedFields?.includes('Rebate or Discount')">
      <th class="header">Rebate / Discount</th>
      <td [innerHTML]="rebate">
        {{ rebate }}
      </td>
    </tr>
    <tr *ngIf="savedFields?.includes('Fee Responsibility or Third-Party Contribution')">
      <th class="header">Fee Responsibility / Third-Party Contribution</th>
      <td [innerHTML]="feeResponsibility">
        {{ feeResponsibility }}
      </td>
    </tr>
    <tr *ngIf="savedFields?.includes('Payment Terms')">
      <th class="header">Payment Terms</th>
      <td [innerHTML]="paymentTerms">
        {{ paymentTerms }}
      </td>
    </tr>
    <tr *ngIf="savedFields?.includes('Early Termination')">
      <th class="header">Early Termination / Abortive Fees</th>
      <td [innerHTML]="earlyTermination">
        {{ earlyTermination }}
      </td>
    </tr>
    <tr *ngIf="savedFields?.includes('Reimbursable Expenses')">
      <th class="header">Reimbursable Expenses</th>
      <td [innerHTML]="reimbursableExpences">
        {{ reimbursableExpences }}
      </td>
    </tr>
    <tr *ngIf="savedFields?.includes('Other Specialities')">
      <th class="header">Other Specialist</th>
      <td [innerHTML]="otherSpecialist">
        {{ otherSpecialist }}
      </td>
    </tr>
    <tr *ngIf="savedFields?.includes('Invoicing Requirements & Purchase Order')">
      <th class="header">Invoicing Requirements & Purchase Order</th>
      <td [innerHTML]="purchaseOrder">{{ purchaseOrder }}</td>
    </tr>
  </tbody>
</table>

<table id="table2">
  <tbody>
    <tr>
      <th class="header">Date Created</th>
      <td>{{ createdDate }}</td>
    </tr>
    <tr>
      <th class="header">Date Submitted to Client</th>
      <td>
        {{ dateSubmitedToClient }}
      </td>
    </tr>
    <tr>
      <th class="header">Client Approved</th>
      <td>{{ selectedData?.approvalAttained }}</td>
    </tr>
    <tr>
      <th class="header">Date Approved/Rejected by Client</th>
      <td>
        {{ dateApprovedByClient }}
      </td>
    </tr>
    <tr>
      <th class="header">C&W System ID</th>
      <td>
        {{ selectedData?.cwSystemProjectId }}
      </td>
    </tr>
    <tr>
      <th class="header">Supporting Documents</th>
      <td>
        {{ documentNames }}
      </td>
    </tr>
  </tbody>
</table>
<table id="table3">
  <tbody *ngFor="let requestAudit of requestAudits; let i = last">
    <tr>
      <th class="header">Change By</th>
      <td colspan="2">{{ requestAudit?.modifiedBy }}</td>
    </tr>
    <tr>
      <th class="header">Change Date</th>
      <td colspan="2">{{ requestAudit?.modifiedOnString }}</td>
    </tr>
    <tr>
      <th class="header">Field</th>
      <th class="header">Changed From</th>
      <th class="header">Changed To</th>
    </tr>
    <tr *ngFor="let change of requestAudit.changes">
      <td>{{ change.field }}</td>
      <td [innerHTML]="change.oldValue">{{ change.oldValue }}</td>
      <td [innerHTML]="change.newValue">{{ change.newValue }}</td>
    </tr>
    <tr *ngIf="!i">
      <th colspan="3"></th>
    </tr>
  </tbody>
</table>
