<div class="modal-header">
  <h4 id="groupCreate">{{ dataMode }} Link</h4>
</div>
<form role="form" [formGroup]="dataForm">
  <div class="modal-body modal-full-height-200">
    <div class="form-group d-flex flex-column mb-3">
      <label for="title" class="color-theme-3">Title<span class="text-danger">*</span></label>
      <div class="d-flex flex-row">
        <div class="d-flex flex-column w-100">
          <input type="text" class="form-control" formControlName="title" name="title" />
          <div
            *ngIf="dataForm.controls.title.invalid && (dataForm.controls.title.dirty || dataForm.controls.title.touched || isInvalid)"
            class="text-danger"
          >
            <div *ngIf="dataForm.controls.title.errors?.['required']">
              <small>{{ messages.required }}</small>
            </div>
            <div *ngIf="dataForm.controls.title.errors?.maxlength">
              <small>Title should be less than 100 characters.</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group d-flex flex-column mb-3">
      <label for="link" class="color-theme-3">Link<span class="text-danger">*</span></label>
      <div class="d-flex flex-row">
        <div class="d-flex flex-column w-100">
          <input type="url" class="form-control" formControlName="link" name="link" />
          <div
            *ngIf="dataForm.controls.link.invalid && (dataForm.controls.link.dirty || dataForm.controls.link.touched || isInvalid)"
            class="text-danger"
          >
            <div *ngIf="dataForm.controls.link.errors?.['required']">
              <small>{{ messages.required }}</small>
            </div>
            <div *ngIf="dataForm.controls.link.errors?.maxlength">
              <small>Link should be less than 500 characters.</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" type="button" id="saveButton" [disabled]="isButtonsDisabled" (click)="onSubmit()">{{ dataMode }}</button>
    <button class="btn btn-outline-primary" type="button" (click)="onCancel()">Cancel</button>
  </div>
</form>
