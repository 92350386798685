<app-loading class="h-100"></app-loading>
<div id="app-container" class="h-100" [className]="sidebar?.containerClassnames">
  <app-top-nav></app-top-nav>
  <app-sidebar></app-sidebar>
  <main>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </main>
  <app-footer></app-footer>
</div>
