import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CONSTANTS } from '../constants/project.constant';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private readonly longTimeout = 10000;
  constructor(private readonly toastr: ToastrService) {}

  failure(message: string): void {
    this.toastr.error(message, 'Oops!');
  }

  stickyFailure(message: string): void {
    this.toastr.error(message, 'Oops!', { closeButton: true, disableTimeOut: true });
  }

  conflict(): void {
    this.failure(CONSTANTS.conflictErrorMessage);
  }

  genericError(): void {
    this.failure(CONSTANTS.genericErrorMessage);
  }

  hint(message: string): void {
    this.toastr.info(message, 'Hint');
  }

  titledLongHint(title: string, message: string): void {
    this.toastr.info(message, title, { timeOut: this.longTimeout });
  }

  stickyHint(message: string): void {
    this.stickyTitledHint('Hint', message);
  }

  stickyTitledHint(title: string, message: string): void {
    this.toastr.info(message, title, { closeButton: true, disableTimeOut: true });
  }

  success(message: string): void {
    this.toastr.success(message == 'Declined' ? 'Rejected' : message, 'Success');
  }

  stickySuccess(message: string): void {
    this.stickyTitledHint('Success', message);
  }

  stickyFullWidthWarning(message: string): void {
    this.toastr.warning(message, 'Warning', {
      tapToDismiss: false,
      closeButton: true,
      disableTimeOut: true,
      positionClass: 'toast-bottom-full-width'
    });
  }

  dismissAll(): void {
    this.toastr.clear();
  }
}
