import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AdUser, User } from 'src/app/shared/models/user.model';
import { environment } from 'src/environments/environment';
import { FunctionUrls } from '../constants/function-urls.constant';
import { Client, ClientSelectorData } from '../models/client';
import { BaseDataService } from './base-api-service';
import { StorageService } from './local-storage.service';

import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseDataService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private _currentUser!: User;

  currentUser$ = new ReplaySubject<User>(1);
  currentAdUser$ = new BehaviorSubject<AdUser | null>(null);
  isLoggedIn$ = new ReplaySubject<boolean>(1);
  private readonly clients$ = new ReplaySubject<Client[]>(1);

  isFromLogout = localStorage.getItem('isFromLogout') == '1';

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private readonly msalGuardConfig: MsalGuardConfiguration,
    private readonly msalService: MsalService,
    private readonly msalBroadcastService: MsalBroadcastService,
    httpClient: HttpClient,
    notify: NotificationService,
    private readonly storageService: StorageService
  ) {
    super(httpClient, notify);
    this._currentUser = User.unknownUserInstance;
    if (this.msalService.instance.getAllAccounts().length > 0) {
      this.setCurrentUser(this.msalService.instance.getAllAccounts()[0]);
    }
    this.subscribeToLoginEvents();
  }

  get currentUserAsObservable(): Observable<User> {
    return this.currentUser$.asObservable();
  }

  get currentUser(): User {
    return this._currentUser;
  }

  private subscribeToLoginEvents(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.SSO_SILENT_SUCCESS))
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(payload.account);
        this.setCurrentUser(payload.account);
      });

    this.msalBroadcastService.inProgress$.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None)).subscribe(() => {
      this.setLoggedInState();
    });
  }

  private setCurrentUser(account: AccountInfo | null): void {
    if (!account) {
      this.currentUser$.next(User.unknownUserInstance);
      return;
    }

    this.getOne<User>(FunctionUrls.dataApi.getLoggedInUser, false)
      .pipe(take(1))
      .subscribe(
        (user) => {
          let selectedData = this.storageService.lastClientSelectorData;
          if (selectedData.clientGuid == '' || selectedData.clientGuid === undefined) {
            selectedData = new ClientSelectorData();
            selectedData.clientGuid = user.roleClients[0].clientGuid!;
            selectedData.clientDateFormat = user.roleClients[0].dateFormat!;
            selectedData.clients = user.roleClients;
            this.storageService.lastClientSelectorData = selectedData;
          } else {
            selectedData.clientDateFormat = user.roleClients.find((x) => x.clientGuid === selectedData.clientGuid)!.dateFormat!;
            this.storageService.lastClientSelectorData = selectedData;
          }

          this._currentUser = new User(user);
          this.currentUser$.next(this._currentUser);
          this.clients$.next(this._currentUser.roleClients.map((item) => new Client(item)).sort((a, b) => (a.clientName < b.clientName ? -1 : 1)));
        },
        (error) => {
          this.currentUser$.error(error);
        }
      );
  }

  login(): void {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.msalService.loginRedirect({ redirectStartPage: environment.azureRedirectUrl } as RedirectRequest);
    }
  }

  logout(): void {
    localStorage.clear();
    localStorage.setItem('isFromLogout', '1');
    this.msalService.logoutRedirect();
    this._currentUser = User.unknownUserInstance;
    this.currentUser$.next(this._currentUser);
    this.clients$.next([]);
  }

  setLoggedInState(): void {
    if (this.msalService.instance.getAllAccounts().length == 0 && !this.isFromLogout) {
      this.login();
    }

    const isLoggedIn = this.msalService.instance.getAllAccounts().length > 0;
    this.isLoggedIn$.next(isLoggedIn);
    if (isLoggedIn) {
      const account = this.msalService.instance.getAllAccounts()[0];
      this.currentAdUser$.next(new AdUser(account.name ?? account.username, account.username));
    } else {
      this.currentAdUser$.next(null);
    }
  }

  getClients(): Observable<Client[]> {
    return this.clients$.asObservable();
  }
}
