export const environment = {
  production: true,
  isAuthGuardActive: true,
  azureApplicationId: '0c2d14a9-3484-4cb3-8ce3-ce61bfd4acb5',
  azureApiScopeUri: 'api://0c2d14a9-3484-4cb3-8ce3-ce61bfd4acb5/api.access',
  azureDirectoryId: '46c5178e-a0f4-4f4d-8c40-9598e3d11860',
  appInsightsKey: '5fd3c043-61aa-4177-95db-1d454a40e77c',
  azureRedirectUrl: 'https://commissioning.cushwake.com/',
  azureDataFunctionUrl: 'https://cw-emea-comtool-p-api.azurewebsites.net',
  graphApiUrl: 'https://graph.microsoft.com/v1.0',
  version: 'PROD - V 2.0.0',
  defaultMenuType: 'menu-default',
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  isMockData: false
};
