import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { CONSTANTS } from '../constants/project.constant';

@Injectable()
export abstract class BaseComponent implements OnDestroy {
  protected readonly destroyed$ = new Subject<void>();
  isLoading = true;
  isInvalid = false;
  selectedClientGuid = '';
  isButtonsDisabled = false;
  noSearchResults = CONSTANTS.emptySearchResultsMessage as string;
  searchText = '';

  protected startLoading(): void {
    this.isLoading = true;
  }

  protected endLoading(): void {
    this.isLoading = false;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  protected scrollToTop(): void {
    window.scrollTo(0, 0);
  }
}
