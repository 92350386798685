import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { lastValueFrom, takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { DataMode } from 'src/app/shared/constants/data-mode';
import { CONSTANTS } from 'src/app/shared/constants/project.constant';
import { Client } from 'src/app/shared/models/client';
import { ClientLink } from 'src/app/shared/models/client-link';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ClientLinkService } from '../../../services/client-link.service';

@Component({
  selector: 'app-client-link-create',
  templateUrl: './client-link-create.component.html',
  styleUrls: ['./client-link-create.component.scss']
})
export class ClientLinkCreateComponent extends BaseComponent {
  modalRef!: BsModalRef;
  dataForm!: FormGroup;
  dataMode!: DataMode;
  clientList: Client[] = [];
  messages = {
    required: 'Field cannot be empty',
    minlength: 'Field must be at least 4 characters long'
  };

  @ViewChild('template', { static: true }) template!: TemplateRef<any>;
  @Input() selectedData!: ClientLink;
  @Output() onCreateCancel = new EventEmitter<void>();
  @Output() onCreateEvent = new EventEmitter<void>();

  constructor(private readonly notify: NotificationService, private formBuilder: FormBuilder, private readonly clientLinkService: ClientLinkService) {
    super();
  }

  ngOnInit(): void {
    this.initFormGroup();
    this.setDataMode();

    if (this.dataMode == DataMode.Update) {
      this.loadData();
    }
  }

  setDataMode(): void {
    this.dataMode = this.selectedData?.clientLinkId ? DataMode.Update : DataMode.Create;
  }

  public initFormGroup(): void {
    this.dataForm = this.formBuilder.group({
      clientLinkId: [0],
      title: ['', [Validators.required, Validators.maxLength(100)]],
      link: ['', [Validators.required, Validators.maxLength(500)]],
      isActive: [true],
      clientGuid: [this.selectedData?.clientGuid, Validators.required]
    });
  }

  onCancel(): void {
    this.onCreateCancel.emit();
  }

  async onSubmit(): Promise<void> {
    this.isButtonsDisabled = true;
    this.dataForm.patchValue({
      title: this.dataForm.get('title')?.value.trim(),
      link: this.dataForm.get('link')?.value.trim()
    });

    if (this.dataForm.invalid) {
      this.isInvalid = true;
      this.notify.hint(CONSTANTS.fixValidations);
      this.isButtonsDisabled = false;
      return;
    }

    if (await this.duplicateCheck()) {
      this.notify.hint(`Link '${this.title}' already exists`);
      this.isButtonsDisabled = false;
      return;
    }

    try {
      new URL(this.link);
    } catch (_) {
      this.notify.hint(`Invalid link '${this.link}' `);
      this.isButtonsDisabled = false;
      return;
    }

    this.clientLinkService
      .create(this.dataForm.getRawValue())
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.notify.success(`Link '${this.title}' successfully ${this.dataMode.toLowerCase()}d.`);
        this.clear();
        this.onCreateEvent.emit();
      });
  }

  private async duplicateCheck(): Promise<boolean> {
    let dataList = await lastValueFrom(this.clientLinkService.getData(this.selectedData.clientGuid));

    if (this.dataMode == DataMode.Update) {
      dataList = dataList.filter((item: ClientLink) => item.clientLinkId !== this.clientLinkId);
    }

    return dataList.filter((item: ClientLink) => item.title.toLowerCase() === this.title.toLowerCase()).length !== 0;
  }

  loadData(): void {
    this.dataForm.patchValue({
      clientLinkId: this.selectedData.clientLinkId,
      title: this.selectedData.title,
      link: this.selectedData.link,
      clientGuid: this.selectedData?.clientGuid
    });
  }

  clear(): void {
    this.dataForm.reset();
    this.isButtonsDisabled = false;
    this.isInvalid = false;
  }

  get title(): string {
    return this.dataForm.controls['title'].value;
  }

  get link(): string {
    return this.dataForm.controls['link'].value;
  }

  get clientLinkId(): number {
    return this.dataForm.controls['clientLinkId'].value;
  }
}
