import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { controlTelemetry } from 'src/app/shared/constants/mandatory-formatters.constant';

@Directive({
  selector: '[appMandatoryformatter]'
})
export class MandatoryFormatterDirective implements OnInit {
  @Input() controlTelemetry = controlTelemetry;
  constructor(private el: ElementRef) {}
  ngOnInit(): void {
    this.setElementTelemetry();
  }

  setElementTelemetry(): void {
    if (this.controlTelemetry['theme'] == 'Asterix') {
      this.el.nativeElement.innerText = '*';
      this.el.nativeElement.style.backgroundColor = this.controlTelemetry['backColor'];
      this.el.nativeElement.style.color = this.controlTelemetry['textColor'];
      this.el.nativeElement.style.fontSize = this.controlTelemetry['fontSize'];
    } else {
      this.el.nativeElement.innerText = this.el.nativeElement.innerText === '' ? 'Mandatory' : this.el.nativeElement.innerText;
      this.el.nativeElement.style.padding = '2px 10px';
      if (this.controlTelemetry['boxMode'] == 'RoundCorners') {
        this.el.nativeElement.style.borderRadius = '50rem';
      }
      this.el.nativeElement.style.backgroundColor = this.controlTelemetry['backColor'];
      this.el.nativeElement.style.color = this.controlTelemetry['textColor'];
      this.el.nativeElement.style.fontSize = this.controlTelemetry['fontSize'];
      this.el.nativeElement.style.border = this.controlTelemetry['border'];
      this.el.nativeElement.style.fontWeight = this.controlTelemetry['fontWeight'];
    }
  }
}
