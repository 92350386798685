import { Component, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import MODAL_ABOUT_CONFIG from '../../constants/modal-about.constant';

@Component({
  selector: 'app-about-ctool',
  templateUrl: './about-ctool.component.html',
  styleUrls: ['./about-ctool.component.scss']
})
export class AboutCToolComponent {
  @ViewChild('aboutTemplate', { static: true }) template!: TemplateRef<any>;

  modalRef!: BsModalRef;
  currentYear = new Date().getFullYear();

  constructor(private readonly modalService: BsModalService) {}

  show(): void {
    this.modalRef = this.modalService.show(this.template, MODAL_ABOUT_CONFIG);
  }

  onCancel(): void {
    this.modalRef.hide();
  }

  get version(): string {
    return environment.version;
  }
}
