/* eslint-disable indent */
/* eslint-disable @typescript-eslint/naming-convention */
import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import moment from 'moment';
import { requestStatus } from '../constants/request-status.constants';
import { CommissionRequest } from '../models/commissionRequest';

const enum RequestAccentType {
  LightGrey = 'accent-light',
  LightBlue = 'accent-info',
  Yellow = 'accent-warning',
  DarkRed = 'accent-secondary',
  Green = 'accent-success',
  Black = 'accent-dark',
  New = 'accent-new'
}

@Directive({
  selector: '[requestAccent]'
})
export class RequestAccentDirective implements OnInit {
  @Input() commissionRequest!: CommissionRequest;

  constructor(private readonly elementRef: ElementRef, private readonly renderer: Renderer2) {}

  ngOnInit(): void {
    this.setAccentClass();
  }

  private setAccentClass(): void {
    let accentCss: RequestAccentType = RequestAccentType.LightGrey;
    if (this.commissionRequest.statusName == requestStatus.aborted.name || this.commissionRequest.statusName == requestStatus.completed.name) {
      this.renderer.addClass(this.elementRef.nativeElement, accentCss);
      return;
    }
    const delayedDays = moment.duration(moment().diff(moment(this.commissionRequest.modifiedOn))).asDays();

    if (delayedDays >= 5 && delayedDays < 10) {
      accentCss = RequestAccentType.Yellow;
    }
    if (delayedDays >= 10) {
      accentCss = RequestAccentType.DarkRed;
    }

    this.renderer.addClass(this.elementRef.nativeElement, accentCss);
  }
}
