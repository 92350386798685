import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { takeUntil } from 'rxjs';
import { BasePagingComponent } from 'src/app/shared/components/base-paging.component';
import { ConfirmComponent } from 'src/app/shared/components/confirm/confirm.component';
import MODAL_CONFIRM_CONFIG from 'src/app/shared/constants/modal-confirm.constant';
import MODAL_RIGHT_CONFIG from 'src/app/shared/constants/modal-right.constant';
import { UserRole } from 'src/app/shared/constants/roles.constant';
import { ClientLink } from 'src/app/shared/models/client-link';
import { ConfirmReturn } from 'src/app/shared/models/confirm-return';
import { StorageService } from 'src/app/shared/services/local-storage.service';
import { MenuService } from 'src/app/shared/services/menu.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ClientLinkService } from '../../services/client-link.service';

@Component({
  selector: 'app-client-link',
  templateUrl: './client-link.component.html',
  styleUrls: ['./client-link.component.scss']
})
export class ClientLinkComponent extends BasePagingComponent {
  constructor(
    private readonly clientLinkService: ClientLinkService,
    private readonly notify: NotificationService,
    private menuService: MenuService,
    private readonly modalService: BsModalService,
    private readonly currentUserService: UserService,
    route: ActivatedRoute,
    storageService: StorageService
  ) {
    super('ClientLinkList', storageService, route);
  }

  orderBy = 'Description';
  dataList: ClientLink[] = [];
  selectedData: ClientLink | undefined;
  currentUserRole!: UserRole | undefined;
  isActionsVisible = false;

  modalRef!: BsModalRef;
  @ViewChild('confirmFormRef', { static: false }) confirmFormRef!: ConfirmComponent;
  @ViewChild('template', { static: true }) template!: TemplateRef<unknown>;

  ngOnInit(): void {
    this.menuService.setBreadCrumbTitle('Links');
    this.loadData();
  }

  addNewLink(): void {
    if (this.selectedClientGuid === '') {
      this.notify.hint('You need to select a client.');
      return;
    }
    this.selectedData = new ClientLink({
      clientGuid: this.selectedClientGuid
    });
    this.modalRef = this.modalService.show(this.template, MODAL_RIGHT_CONFIG);
  }

  loadData(): void {
    if (this.selectedClientGuid !== '') {
      this.isLoading = true;
      this.clientLinkService
        .getData(this.selectedClientGuid)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((result) => {
          this.dataList = result;
          this.isLoading = false;
        });

      this.currentUserRole = this.currentUserService.currentUser.roleClients.find((x) => x.clientGuid == this.selectedClientGuid)?.roleName;
      if (this.currentUserRole === 'Administrator') {
        this.isActionsVisible = true;
      }
    }
  }

  clear(): void {
    this.selectedData = undefined;
  }

  confirmed(confirmReturn: ConfirmReturn): void {
    if (confirmReturn.confirm) {
      this.clientLinkService
        .delete(this.selectedData!)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.notify.success(`Link '${this.selectedData!.title}' removed`);
          this.clear();
          this.loadData();
        });
    }
  }

  onEditData(data: ClientLink): void {
    this.selectedData = data;
    this.modalRef = this.modalService.show(this.template, MODAL_RIGHT_CONFIG);
  }

  onCreate(): void {
    this.modalRef.hide();
    this.loadData();
    this.clear();
  }

  onRemoveData(data: ClientLink): void {
    this.selectedData = data;
    this.confirmFormRef.show('Link Removal', `Are you sure you want to remove '${this.selectedData.title}' ?`, 'Yes', 'No', MODAL_CONFIRM_CONFIG);
  }

  onCreateCancel(): void {
    this.modalRef.hide();
    this.clear();
  }

  onChangeClient(selectedClientGuid: string): void {
    this.selectedClientGuid = selectedClientGuid;
    this.dataList = [];
    this.loadData();
  }
}
