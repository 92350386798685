import { Component, ViewChild } from '@angular/core';
import { AboutCToolComponent } from '../about-ctool/about-ctool.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @ViewChild('aboutRef', { static: false }) aboutRef!: AboutCToolComponent;
  currentYear = new Date().getFullYear();
  onClickAbout(): void {
    this.aboutRef.show();
  }
}
