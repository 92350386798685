import { UserRole } from '../constants/roles.constant';

export class Client {
  clientGuid!: string;
  clientName!: string;
  clientEmail!: string;
  clientAddress!: string;
  clientTelephone!: string;
  prefix!: string;
  roleName?: UserRole;
  clientRole?: string;
  dateFormat?: string;
  createdDate!: Date;
  constructor(source: Partial<Client>) {
    this.clientRole = source.clientName + ' - ' + source.roleName;
    Object.assign(this, source);
  }
}

export class ClientSelectorData {
  clientGuid = '';
  clientDateFormat = '';
  clients: Client[] = [];

  constructor(source?: Partial<ClientSelectorData>) {
    Object.assign(this, source);
  }
}
