import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs';
import { PageName } from '../constants/page-name.constant';
import { CONSTANTS } from '../constants/project.constant';
import { StorageService } from '../services/local-storage.service';

import { BaseComponent } from './base.component';

@Injectable()
export abstract class BasePagingComponent extends BaseComponent {
  pageSize: number = CONSTANTS.pageSize;
  currentPage = 1;
  pageName: PageName;
  constructor(@Inject(String) pageName: PageName, protected readonly storageService: StorageService, protected readonly route: ActivatedRoute) {
    super();
    this.pageName = pageName;
    this.pageSize = this.storageService.getItemsPerPage(this.pageName);
  }

  onPageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.resetPagination();
    this.updateLocalStorage(this.pageSize);
  }

  private updateLocalStorage(pageSize: number): void {
    this.storageService.setItemsPerPage(this.pageName, pageSize);
  }

  protected resetPagination(): void {
    this.currentPage = 1;
  }

  protected onPageChange(currentPage: number): void {
    this.currentPage = currentPage;
    this.scrollToTop();
  }

  protected setCurrentPage(): void {
    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
      this.currentPage = params['page'] ?? this.currentPage;
    });
  }
}
