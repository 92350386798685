import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PdfSharedService {
  invokePDFGenarator = new EventEmitter();

  genaratePDF(): void {
    this.invokePDFGenarator.emit();
  }
}
