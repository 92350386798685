import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FunctionUrls } from 'src/app/shared/constants/function-urls.constant';
import { BaseDataService } from 'src/app/shared/services/base-api-service';
import { ClientConfigurations } from '../models/client-config';

@Injectable({
  providedIn: 'root'
})
export class ClientAdminConfigService extends BaseDataService {
  private readonly clientAdminConfigMap = new Map<string, ClientConfigurations[]>();
  getData(clientGuid: string): Observable<ClientConfigurations[]> {
    return new Observable((subscriber) => {
      this.getMappedList<ClientConfigurations>(`${FunctionUrls.dataApi.getClientAdminConfig}/${clientGuid}`, ClientConfigurations).subscribe({
        next: (result) => {
          this.clientAdminConfigMap.set(clientGuid, result);
          subscriber.next(result);
        },
        error: (error) => {
          subscriber.error(error);
        }
      });
    });
  }

  create(data: ClientConfigurations[]): Observable<string> {
    return this.post<string>(FunctionUrls.dataApi.creatClientAdminConfig, data);
  }
}
