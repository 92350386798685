export class ClientConfigurations {
  id!: number;
  clientGuid!: string;
  configurableFieldId!: number;
  configurableField!: ConfigurationFields;
  prepopulatedText!: string;
  isActive!: boolean;
  isRequired!: boolean;
  constructor(source: Partial<ClientConfigurations>) {
    Object.assign(this, source);
  }
}

export class ConfigurationFields {
  id!: number;
  fieldName!: string;
  constructor(source: Partial<ConfigurationFields>) {
    Object.assign(this, source);
  }
}
