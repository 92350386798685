import { UserRole } from '../constants/roles.constant';
import { Client } from './client';

export class User {
  userId!: number;
  name!: string;
  email!: string;
  nameEmail!: string;
  roleClients: Client[] = [];

  constructor(source: Partial<User>) {
    this.nameEmail = source.name + ' (' + source.email + ')';
    Object.assign(this, source);
  }

  get firstName(): string {
    const firstName = this.name.substring(0, this.name.indexOf(' '));
    return firstName === '' ? this.name : firstName;
  }

  isAuthorized(): boolean {
    return this.userId !== 0;
  }

  hasAnyRole(roles: UserRole[], clientGuid: string): boolean {
    return roles.some((role) => role === this.roleClients.find((x) => x.clientGuid === clientGuid)?.roleName);
  }

  static unknownUserInstance = new User({
    userId: 0,
    name: 'Unknown User',
    email: '',
    roleClients: []
  });
}

export class AdUser {
  name: string;
  email: string;

  constructor(name: string, email: string) {
    this.name = name;
    this.email = email;
  }
}

export class RoleClient {
  clientName?: string;
  clientGuid?: number;

  constructor(source: Partial<RoleClient>) {
    Object.assign(this, source);
  }
}
