import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxPaginationModule } from 'ngx-pagination';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { NgPipesModule, UniquePipe } from 'ngx-pipes';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AboutCToolComponent } from './components/about-ctool/about-ctool.component';
import { ClientSelectorComponent } from './components/client-selector/client-selector.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { EmptyDataCardComponent } from './components/empty-data-card/empty-data-card.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PdfGenerateComponent } from './components/pdf-generate/pdf-generate.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { MandatoryFormatterDirective } from './directives/mandatoryformatter.directive';
import { NumbersOnlyDirective } from './directives/number-only.directive';
import { PreventKeyUpDirective } from './directives/preventKeyUp.directive';
import { RequestAccentDirective } from './directives/request-color.directive';
import { RequestStatusColorDirective } from './directives/request-status-color.directive';
import { TwoDigitDecimalNumberDirective } from './directives/two-digit-decimal-number.directive';
import { SecureImagePipe } from './pipes/secure-image.pipe';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    TopNavComponent,
    SidebarComponent,
    LoadingComponent,
    FooterComponent,
    AboutCToolComponent,
    SecureImagePipe,
    PaginationComponent,
    PaginationComponent,
    ConfirmComponent,
    EmptyDataCardComponent,
    ClientSelectorComponent,
    RequestAccentDirective,
    MandatoryFormatterDirective,
    TwoDigitDecimalNumberDirective,
    NumbersOnlyDirective,
    RequestStatusColorDirective,
    PreventKeyUpDirective,
    PdfGenerateComponent
  ],
  imports: [
    FormsModule,
    RouterModule,
    NgPipesModule,
    PerfectScrollbarModule,
    CommonModule,
    TooltipModule.forRoot(),
    NgxPaginationModule,
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    AccordionModule.forRoot(),
    BsDropdownModule,
    ReactiveFormsModule,
    NgSelectModule
  ],
  exports: [
    TopNavComponent,
    SidebarComponent,
    FooterComponent,
    AboutCToolComponent,
    NgPipesModule,
    PerfectScrollbarModule,
    LoadingComponent,
    FormsModule,
    SecureImagePipe,
    PerfectScrollbarComponent,
    PaginationComponent,
    PaginationComponent,
    BsDropdownModule,
    TabsModule,
    AccordionModule,
    NgxPaginationModule,
    ConfirmComponent,
    ReactiveFormsModule,
    EmptyDataCardComponent,
    ClientSelectorComponent,
    RequestAccentDirective,
    MandatoryFormatterDirective,
    NgSelectModule,
    NgSelectModule,
    TwoDigitDecimalNumberDirective,
    NumbersOnlyDirective,
    RequestStatusColorDirective,
    PreventKeyUpDirective,
    PdfGenerateComponent
  ],
  providers: [
    UniquePipe,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class SharedModule {}
