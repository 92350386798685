export type RequestStatus =
  | RequestStatusTypes.Draft
  | RequestStatusTypes.SubmittedForReview
  | RequestStatusTypes.AwaitingRequesterApproval
  | RequestStatusTypes.Approved
  | RequestStatusTypes.Declined
  | RequestStatusTypes.Aborted
  | RequestStatusTypes.Completed;

export enum RequestStatusTypes {
  Draft = 'Draft',
  SubmittedForReview = 'Submitted for Review',
  SubmitForReview = 'Submit for Review',
  AwaitingRequesterApproval = 'Awaiting Client Lead Approval',
  Approved = 'Approved',
  Approve = 'Approve',
  Declined = 'Declined',
  Decline = 'Decline',
  Aborted = 'Aborted',
  Abort = 'Abort',
  Completed = 'Completed',
  Complete = 'Complete'
}

export interface IStatus {
  id: number;
  name?: RequestStatus;
  confirmName?: string;
  commentBoxName?: string;
}

const draft: IStatus = {
  id: 1,
  name: RequestStatusTypes.Draft
};
const submitForReview: IStatus = {
  id: 2,
  name: RequestStatusTypes.SubmittedForReview,
  confirmName: RequestStatusTypes.SubmitForReview
};
const awaitingRequesterApproval: IStatus = {
  id: 3,
  name: RequestStatusTypes.AwaitingRequesterApproval,
  confirmName: 'Submit to Client Lead'
};

const approve: IStatus = {
  id: 4,
  name: RequestStatusTypes.Approved,
  confirmName: RequestStatusTypes.Approve
};
const decline: IStatus = {
  id: 5,
  name: RequestStatusTypes.Declined,
  confirmName: RequestStatusTypes.Decline,
  commentBoxName: 'rejecting'
};
const abort: IStatus = {
  id: 6,
  name: RequestStatusTypes.Aborted,
  confirmName: RequestStatusTypes.Abort,
  commentBoxName: 'aborting'
};
const complete: IStatus = {
  id: 7,
  name: RequestStatusTypes.Completed,
  confirmName: RequestStatusTypes.Complete
};

export const requestStatus = {
  draft: draft,
  submittedForReview: submitForReview,
  awaitingRequesterApproval: awaitingRequesterApproval,
  approved: approve,
  declined: decline,
  aborted: abort,
  completed: complete
};
