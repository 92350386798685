import { UserRole } from './roles.constant';

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: UserRole[];
  visible?: boolean;
}

const administrators: UserRole[] = ['Administrator'];
const nonAdmin: UserRole[] = ['Administrator', 'CW Lead', 'CW Delegate', 'External User'];

const data: IMenuItem[] = [
  {
    icon: 'fal fa-list',
    label: 'Requests',
    to: '/requests/list',
    visible: true,
    roles: nonAdmin
  },
  // {
  //   icon: 'fal fa-link',
  //   label: 'Links',
  //   to: '/links',
  //   visible: true,
  //   roles: nonAdmin
  // },

  {
    icon: 'fal fa-sliders-v-square icon',
    label: 'Administration',
    to: '/admin',
    visible: true,
    roles: administrators,
    subs: [
      {
        to: '/admin/clients',
        label: 'Configuration',
        visible: true,
        roles: administrators,
        icon: 'fal fa-user'
      },
      {
        to: '/admin/business-unit',
        label: 'Business Units',
        visible: true,
        roles: administrators,
        icon: 'fal fa-users'
      },
      {
        to: '/admin/project-types',
        label: 'Project Types',
        visible: true,
        roles: administrators,
        icon: 'fa fa-comment-dots'
      },
      {
        to: '/admin/property-types',
        label: 'Property Types',
        visible: true,
        roles: administrators,
        icon: 'fa fa-tasks'
      },
      { to: '/admin/users', label: 'Users', visible: true, roles: administrators, icon: 'fal fa-users' }
    ]
  }
];
export default data;
