<div *ngIf="canPickClient" class="top-drop-downs">
  <!-- <label class="color-theme-3 mt-2 mr-2">Client: </label> -->
  <div class="float-right mr-2 w-15 home-drop-down-wrapper">
    <ng-select
      id="clientGuid"
      inputClass="form-control"
      [(ngModel)]="data.clientGuid"
      [disabled]="isReadonly"
      [items]="data.clients"
      bindLabel="clientRole"
      bindValue="clientGuid"
      [virtualScroll]="true"
      [searchable]="true"
      [clearable]="false"
      placeholder="Select a Client"
      (change)="onClientChange()"
    ></ng-select>
  </div>
</div>

<div *ngIf="!canPickClient" class="top-drop-downs">
  <label class="color-theme-3 mt-2 mr-2"> {{ data.clients[0].clientRole }} </label>
</div>
