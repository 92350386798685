import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

@Pipe({
  name: 'secureImage'
})
export class SecureImagePipe implements PipeTransform {
  private readonly defaultUserPicture = './../../assets/images/default-user.png';
  constructor(private readonly http: HttpClient) {}

  transform(url: string): Observable<string> {
    return new Observable<string>((observer) => {
      observer.next(this.defaultUserPicture);

      this.http.get(url, { responseType: 'blob' }).subscribe(
        (response) => {
          const reader = new FileReader();
          if (response && response.type.match('image.*')) {
            reader.readAsDataURL(response);
          }
          reader.onloadend = function () {
            observer.next(reader.result as string);
          };
        },
        () => {
          observer.next(this.defaultUserPicture);
        }
      );
    });
  }
}
